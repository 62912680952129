import React from "react";

import {
  Button,
  Typography,
  FormControl,
  MenuItem,
  Paper,
  Select,
  // Customizable Area Start
  createStyles
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import ConvertfileController, {
  Props,
  configJSON,
} from "./ConvertfileController";


export class Convertfile extends ConvertfileController {

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
          <Paper className={classes.root}>
                <Typography variant="h4" gutterBottom>
                    File Converter
                </Typography>
                <div className={classes.mainWrapper}>
                    <h2>Convert your files easily</h2>
                    <CloudUploadIcon className={classes.uploadIcon} />

                    <div className={classes.flexBoxWrap}>
                        {
                            this.state.selectedFile !== null ? (
                                <div className={classes.card}>
                                    
                                    <div className={classes.cardName}>
                                    {this.state?.selectedFile?.fileData &&
                                        Array.from(this.state.selectedFile.fileData).map(file => file.name).join(', ')}
                                    </div>
                                    <div className={classes.changeToformat}>
                                        <FormControl variant="outlined" className={classes.formControl} size="small">
                                            <label htmlFor="">Convert to</label>
                                            <Select
                                                data-test-id="selectValue"
                                                value={this.state.selectValue}
                                                label={"Select"}
                                                onChange={this.handleSelect}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                            >
                                                <MenuItem value="pdf">PDF</MenuItem>
                                                <MenuItem value="zip">ZIP</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
        
                                    <div className={classes.fileSize}>
                                        {this.state?.selectedFile?.fileData && this.state.selectedFile.fileData[0]?.size}kb
                                    </div>
                                    <div className={classes.fileRemove}>
                                        <CloseIcon data-test-id='file-remove' onClick={this.handleRemoveFile} />
                                    </div>
                                </div>
                            ) : ''
                        }
                        {/* uploadContainer */}
                        <div className={classes.uploadWrapper}>
                            <div className={classes.uploadBtnWrapper}>
                                <input data-test-id='input-choose-file' type="file" accept=".txt,.pdf,.docx,.json,.png,.jpeg,.jpg" onChange={this.handleFileUpload}  onClick={this.handleSetNull} multiple />
                              <button data-test-id='browse-btn' className={classes.btn}>Browse a file</button>
                            </div>

                            <Button data-test-id='convert-button'  variant="contained" color={this.state.convertAPILoader || !this.state.selectedFile? "primary" :"secondary"} onClick={this.handleConvertfile} disabled={this.state.convertAPILoader || !this.state.selectedFile? true :false}>
                                Convert
                            </Button>
                        </div>
                    </div>
                </div>
            </Paper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const useStyles = () => {
  return createStyles({
  root: {
      padding: theme.spacing(3),
      height: '100vh',
      textAlign: 'center',
  },
  mainWrapper: {
      color: '#0B3462',
      backgroundColor: '#eee',
      padding: '60px',
  paddingTop: '40px',
  borderRadius: '22px',
  },
  uploadContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
  },
  uploadIcon: {
      fontSize: 80,
      marginBottom: theme.spacing(2),
  },
  formControl: {
      minWidth: 100,
  },
  flexBoxWrap: {
      border: '2px dashed #000',
      padding: '30px 20px',
      borderRadius: '22px',
  },
  card: {
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '15px',
      marginBottom: '15px',
      borderRadius: '16px',
      borderColor: 'red'
  },
  fileRemove:{
      cursor: 'pointer'
  },
  uploadWrapper: {
      '& input': {
          width: '150px',
      },
      padding: '15px',
      borderRadius: '16px',
      display: 'flex',
      justifyContent: 'center',
  },
  cardName: {
      marginBottom: '15px'
  },
  changeToformat: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
  },
  uploadBtnWrapper: {
      position: 'relative',
      overflow: 'hidden',
      display: 'inline-block',
      margin: '0px 15px',
      '& input[type=file]': {
          fontSize: '100px',
          position: 'absolute',
          left: '0',
          top: '0',
          opacity: '0'
      }
    },
    btn: {
      border: '2px solid #0B3462',
      color: '#0B3462',
      backgroundColor: 'white',
      padding: '8px 20px',
      borderRadius: '8px',
      fontSize: '20px',
      fontWeight: 'bold',
      cursor: 'pointer'
    },
})
}

export default withStyles(useStyles)(Convertfile);
// Customizable Area End
