import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {Alert,PermissionsAndroid} from "react-native"

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedConvertOption:{label:string;value:string} | null
  optionsModal:boolean;
  selectedFiles:{path:string;mime:string;size:number}[];
  apiResults:string[];
  convertAPILoader:boolean;
  selectedFile: {
    path: string,
    fileData: FileList | null
  } | null,
  selectValue:string|undefined|unknown
 
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ConvertfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  convertOptions:{label:string;value:string}[];
  convertFileAPICallID:string;
  apiConvertfileCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedConvertOption:{label:"PDF",value:"pdf"},
      optionsModal:false,
      selectedFiles:[],
      apiResults:[],
      convertAPILoader:false,
      selectedFile: null,
      selectValue:"pdf",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.convertFileAPICallID = ""
    this.convertOptions =[{label:"PDF",value:"pdf"},{label:"ZIP",value:"zip"}]
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if(message.id === getName(MessageEnum.RestAPIResponceMessage)){
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
    
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (apiRequestId === this.convertFileAPICallID) {
        this.setState({
          convertAPILoader:false
        })
        this.handleConvertFileAPIResponseInMobile(responseJson)
       }
       if(apiRequestId === this.apiConvertfileCallId){
        this.setState({
          convertAPILoader:false
        })
       this.handleConvertFileAPIResponseInWeb(responseJson)
       }
    
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleConvertFileAPIResponseInMobile = (responseJson:{file_url:string;errors:string}) =>{
    if(responseJson && responseJson.file_url){
      this.handleFileConversionAPIResponse(responseJson)
    }else if(responseJson && responseJson.errors){
      this.parseApiErrorResponse(responseJson)
    }
  }

  handleConvertFileAPIResponseInWeb = (responseJson:{file_url:string;errors:string}) =>{
    if(responseJson && responseJson.file_url){
      this.handleDownload(responseJson)
    }else if(responseJson && responseJson.errors){
      this.parseApiErrorResponse(responseJson)
    }
  }

  openOptionsModal = () =>{
    this.setState({
      optionsModal:true
    })
  }

   handleOptionSelect = (option:{label:string;value:string}) => {
    const copySelectedFiles = [...this.state.selectedFiles]
    if(option.value ==="pdf"){
      copySelectedFiles.splice(1)
    }
    this.setState({
      selectedConvertOption:option,
      optionsModal:false,
      selectedFiles:copySelectedFiles
    })
   
  };

   onClose =  () =>{
    this.setState({
      optionsModal:false
    })
  }

  onRemoveFile = (index:number) =>{
    const copyPrevState = [...this.state.selectedFiles]
    copyPrevState.splice(index,1)
    this.setState({
      selectedFiles:copyPrevState
    })
  }

  uploadFile = () => {
    if(this.state.selectedFiles.length <1){
      Alert.alert("Alert","Please upload atleast one file for conversion")
      return
    }
    this.setState({
      convertAPILoader:true
    })
    const header = {
      "Content-Type": configJSON.convertFileAPIContenType
    };

    const formData = new FormData();
    formData.append("convert_extension",this.state.selectedConvertOption?.value as string);
    
    this.state.selectedFiles.forEach((file)=>{
      if (this.state.selectedConvertOption?.value === 'zip') {
        formData.append(`files[]`, {
          uri: String(file.path),
          type: file.mime,
          name: file.path.split("/").pop(),
        } as unknown as Blob);
      } else{
        formData.append("file", {
          uri: String(file.path),
          type: file.mime,
          name: file.path.split("/").pop(),
        } as unknown as Blob);
      
      }
    })
   

    const convertFileMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.convertFileAPICallID = convertFileMsg.messageId;

    convertFileMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.convertFileEndpoint
    );

    convertFileMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    convertFileMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    convertFileMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.convertFileMethod
    );
    runEngine.sendMessage(convertFileMsg.id, convertFileMsg);
  };

 

  handleFileConversionAPIResponse = async (ResponseJson: { file_url: string; })=>{
    if(ResponseJson && ResponseJson.file_url){
      this.setState({
        apiResults:[ResponseJson.file_url,...this.state.apiResults,]
      })
    }
    
  }

  handleDownload = (ResponseJson: { file_url: string; }) => {
    const files = this.state.selectedFile?.fileData;
    let aTag = document.createElement('a');

    if (files && files.length > 0) {
        for (const file of files) {
            let fileName = file.name;
            let matchResult = fileName.match(/^([^(]+)/);
            let viewVector = matchResult ? matchResult[1].trim() : null;
            aTag.href = ResponseJson.file_url;
            aTag.download = `${viewVector}.${this.state.selectValue}`;
            document.body.appendChild(aTag);
           
        }
        aTag.click();
        aTag.remove();
    }
  }

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    
    const files = event.target.files;
    const filesArray = Array.from(files as FileList);

    const validFiles = filesArray.filter((file: File) => {
        const sizeInMB = file.size / (1024 * 1024); 
        return sizeInMB <= 10; 
    });

    const largeFiles = filesArray.filter((file: File) => {
        const sizeInMB = file.size / (1024 * 1024);
        return sizeInMB > 10; 
    });

    if (largeFiles.length > 0) {
        alert(`The following files exceed 10MB and will not be uploaded: ${largeFiles.map(file => file.name).join(', ')}`);
    }

    const temp = {
        path: event.target.value,
        fileData: validFiles as unknown as FileList
    };


    this.setState({ selectedFile:temp.fileData.length ? temp :null});
  }

  handleRemoveFile = () => {
    this.setState({ selectedFile: null })
  }

  handleSelect=(event: React.ChangeEvent<{ value: string|undefined|unknown }>)=>{
    this.setState({
      selectValue:event.target.value,
      
    })
  }

  handleSetNull = (event:React.MouseEvent<HTMLElement>) => {
    (event.target as HTMLInputElement).value = ''
  }


  handleConvertfile = async(event: { preventDefault: () => void; }) => {
    event.preventDefault();

    const headers={
    }
    const formdata = new FormData();
    formdata.append("convert_extension", this.state.selectValue as string);
    const files = this.state.selectedFile?.fileData as unknown as Blob[];

    if (this.state.selectValue === 'zip') {
      for (const file of files) {
        formdata.append("files[]", file);
      }
    } else{
      formdata.append("file", files[0]);

    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiConvertfileCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.convertFileEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.convertFileMethod
    );

    this.setState({
      convertAPILoader:true
    })
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  filterSelectedFiles = (images:{path:string;mime:string;size:number}[]) =>{
    let largeFiles :string[] =[]
    const maxFileSizeInMB = 10
    let validFiles = images.filter((image)=>{
      const sizeInMB = image.size / (1024 * 1024);
      if(sizeInMB > maxFileSizeInMB){
        largeFiles.push(`${image.path.split("/").pop()}`)
      }else{
        return true
      }
    })
    return {
      validFiles,
      largeFiles
    }
  }

  setSelectedFiles = (images:{path:string;mime:string;size:number} | {path:string;mime:string;size:number}[]) =>{
    if(images instanceof Array){
      const updatedFiles = this.filterSelectedFiles(images)
      if (updatedFiles.largeFiles.length > 0) {
        alert(`The following files exceed 10MB and will not be uploaded:\n ${updatedFiles.largeFiles.map(file => file).join(', \n')}`);
      }

      this.setState({
        selectedFiles:[...this.state.selectedFiles,...updatedFiles.validFiles]
      })
    }else{
      let selectedFiles = [...this.state.selectedFiles]
      const updatedSelectedFiles = this.filterSelectedFiles([images])
      if(updatedSelectedFiles.validFiles.length){
        selectedFiles= updatedSelectedFiles.validFiles
      }
      if (updatedSelectedFiles.largeFiles.length > 0) {
        alert(`The following files exceed 10MB and will not be uploaded: ${updatedSelectedFiles.largeFiles.map(file => file).join(', ')}`);
      }
      this.setState({
        selectedFiles
      })
    }
    
  }

  hasAndroidPermission = async ()=> {
    const permission = PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE;
    
    const hasPermission = await PermissionsAndroid.check(permission);
    if (hasPermission) {
    return true;
    }
    const status = await PermissionsAndroid.request(permission);
    return status === 'granted';
  }

  // Customizable Area End
}
