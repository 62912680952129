Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "convertfile";
exports.labelBodyText = "convertfile Body";

exports.btnExampleTitle = "CLICK ME";
exports.btnUploadFileTitle ="Upload Files";
exports.btnConvertTitle ="Convert";
exports.labelResultsText = "Results"
exports.labelConvertToText="Convert to"
//convert file API
exports.convertFileEndpoint ="bx_block_convertfile/attachments/upload_and_convert_file";
exports.convertFileMethod ="POST";
exports.convertFileAPIContenType ="multipart/form-data";


// Customizable Area End